<div class="common-page">
	<div class="common-page__content">
		<span class="common-page__http-status-code-icon">404</span>
		<h1 i18n>Oops... Page not found</h1>
		<h2 i18n>
			The page you are looking for does not exist. Please check the entered URL address. If you continue to encounter
			problems, contact our technical department.
		</h2>
		<div class="common-page__actions">
			<button
				class="common-page__button"
				(click)="goHome()"
				i18n>
				<epic-icon name="sort" />
				Main page
			</button>
		</div>
		<div
			class="common-page__support"
			*ngIf="contactEmail">
			{{ contactEmail }}
		</div>
	</div>
</div>
