<div
	[class.epic-menu--collapsed]="collapseValue()"
	class="epic-menu">
	<div
		(click)="toggleCollapse()"
		class="epic-menu__resize-button">
		<epic-icon name="collapse" />
	</div>
	<div>
		<ng-content select="[menu-top]" />
	</div>
	<div class="epic-menu__center">
		<ng-content select="[menu-item]" />
	</div>
	<div class="epic-menu__bottom">
		<ng-content select="[menu-bottom]" />
	</div>
</div>
