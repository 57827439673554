import { Component, Input } from '@angular/core'
import { IconComponent } from '@core/components/icon/icon.component'
import { NgIf } from '@angular/common'

@Component({
	selector: 'not-found',
	standalone: true,
	imports: [IconComponent, NgIf],
	templateUrl: './not-found.page.html',
	styleUrls: ['./not-found.page.scss', '../common-page.scss'],
})
export class NotFoundPage {
	@Input() contactEmail?: string

	goHome() {
		window.location.href = window.location.hostname
	}
}
