import { Routes } from '@angular/router'
import { AppNavigationComponent } from '@app/app/app-navigation.component'
import { canActivateAuth } from '@core/guards/auth.guard'
import { config } from '@config'

export const routes: Routes = [
	{
		path: '',
		component: AppNavigationComponent,
		canActivate: [canActivateAuth(true, `/${config.modules.auth.path}`)],
		children: [
			{
				path: '',
				redirectTo: config.modules.home.path,
				pathMatch: 'full',
			},
			{
				path: config.modules.home.path,
				loadChildren: () => import('@app/home/home.module').then(m => m.HomeModule),
			},
			{
				path: config.modules.contractors.path,
				loadChildren: () => import('@app/contractors/contractors.module').then(m => m.ContractorsModule),
			},
			{
				path: config.modules.invoices.path,
				loadChildren: () => import('@app/invoices/invoices.module').then(m => m.InvoicesModule),
			},
			{
				path: config.modules.costInvoices.path,
				loadChildren: () => import('@app/cost-invoices/cost-invoices.module').then(m => m.CostInvoicesModule),
			},
			{
				path: config.modules.orders.path,
				loadChildren: () => import('@app/orders/orders.module').then(m => m.OrdersModule),
			},
			{
				path: config.modules.settings.path,
				loadChildren: () => import('@app/settings/settings.module').then(m => m.SettingsModule),
			},
			{
				path: config.modules.ocr.path,
				loadChildren: () => import('@app/ocr/ocr.module').then(m => m.OcrModule),
			},
			{
				path: config.modules.cashDocuments.path,
				loadChildren: () => import('@app/cash-documents/cash-documents.module').then(m => m.CashDocumentsModule),
			},
			{
				path: config.modules.chat.path,
				loadChildren: () => import('@app/chat/chat.module').then(m => m.ChatModule),
			},
			{
				path: config.modules.mailbox.path,
				loadChildren: () => import('@app/mailbox/mailbox.module').then(m => m.MailboxModule),
			},
			{
				path: config.modules.notifications.path,
				loadChildren: () => import('@app/notifications/notifications.module').then(m => m.NotificationsModule),
			},
		],
	},
	{
		path: config.modules.auth.path,
		loadChildren: () => import('@app/auth/auth.module').then(m => m.AuthModule),
	},
]
