import { Component } from '@angular/core'
import { EpicAppComponent } from '@core/components/app/app.component'
import { AppLayout } from '@core/layouts/app/app.layout'
import { MenuComponent } from '@core/components/menu/menu.component'
import { HeadingComponent } from '@core/components/heading/heading.component'
import { Router, RouterLink, RouterOutlet } from '@angular/router'
import { MenuItemComponent } from '@core/components/menu-item/menu-item.component'
import { MenuItem } from '@core/types/menu-item'
import { AuthService } from '@app/auth/services/auth.service'
import { IconComponent } from '@core/components/icon/icon.component'
import { AppService } from '@app/general/services/app.service'
import { LoaderService } from '@core/services/loader.service'
import { config } from '@config'
import { Permission } from '@app/acl/enums/permissions.enum'
import { SignaComponent } from '@app/general/classes/signa-component'
import { NgIf } from '@angular/common'
import { MenuService } from '@core/services/menu.service'
import { AvatarComponent } from '@app/users/components/avatar/avatar.component'
import { ChatModule } from '@app/chat/chat.module'
import { MailboxInnerService } from '@app/mailbox/services/mailbox-inner.service'
import { MailIMAPAccountRead, MailService } from '@app/generated'
import { OrdersInnerService } from '@app/orders/services/orders-inner.service'
import { ContractorInnerService } from '@app/contractors/services/contractor-inner.service'

@Component({
	selector: 'app-app-navigation',
	standalone: true,
	imports: [
		RouterOutlet,
		EpicAppComponent,
		AppLayout,
		MenuComponent,
		HeadingComponent,
		MenuItemComponent,
		IconComponent,
		RouterLink,
		NgIf,
		AvatarComponent,
		ChatModule,
	],
	template: `
		<app-layout>
			<app-chat-modal></app-chat-modal>
			<epic-menu menu>
				<div
					class="epic-menu__top"
					menu-top>
					<!-- TODO REPLACE WITH SVG-->
					<div
						(click)="goToHome()"
						class="epic-menu__top-logo c-pointer"
						[class.collapsed]="menuService.collapseValue()">
						@if (menuService.collapseValue()) {
							<epic-icon source="assets/logo-mini.svg" />
						} @else {
							<img
								alt="menu icon"
								src="assets/logo.svg" />
						}
					</div>
					<div
						class="epic-menu__user"
						[class.collapsed]="menuService.collapseValue()"
						(click)="openProfile()">
						<div
							class="epic-menu__user-row"
							[class.collapsed]="menuService.collapseValue()">
							<div class="menu-avatar">
								<avatar [avatarName]="avatarName()" />
							</div>
							@if (!menuService.collapseValue()) {
								<div class="epic-menu__user-wrapper">
									<span>{{ getUserName }}</span>
									<span class="epic-menu__user-role font__medium">{{ getUserRole }}</span>
								</div>
							}
						</div>
					</div>
				</div>
				@for (item of menuItems; track item) {
					<epic-menu-item
						*ngIf="item.isVisible"
						menu-item
						[collapseState]="false"
						[closeAfterClickOutside]="false"
						[options]="item" />
				}
				@for (item of menuItemsBottom; track item) {
					<epic-menu-item
						*ngIf="item.isVisible"
						menu-bottom
						[collapseState]="true"
						[closeAfterClickOutside]="false"
						[options]="item" />
				}
			</epic-menu>
			<div class="page-container">
				@if (appService.title !== undefined) {
					<epic-heading
						[buttonOptions]="appService.buttonOptions"
						[actionButtonOptions]="appService.actionButtonOptions"
						[text]="appService.title"
						[hideClock]="hideClock()"
						[headerClass]="appService.headerClass"
						[isBlocked]="appService.isBlocked" />
				}
				<router-outlet content />
			</div>
		</app-layout>
	`,
})
export class AppNavigationComponent extends SignaComponent {
	mailAccountsList: MailIMAPAccountRead[] = []
	menuItemsBottom: MenuItem[] = [
		{
			text: $localize`Settings`,
			iconName: 'settings',
			isVisible: true,
			children: [
				{
					text: $localize`General`,
					link: '/settings?page=general',
					isVisible: true,
				},
				{
					text: $localize`Users`,
					link: '/settings?page=users',
					isVisible: this.showMenuItem([Permission.SETTINGS_USERS_READ]),
				},
				{
					text: $localize`Substitutions`,
					link: '/settings?page=replacements',
					isVisible: this.showMenuItem([Permission.REPLACEMENT_READ]),
				},
				{
					text: $localize`Roles`,
					link: '/settings?page=roles',
					isVisible: this.showMenuItem([Permission.ROLES_READ]),
				},
				{
					text: $localize`Dictionaries`,
					link: '/settings?page=dictionaries',
					isVisible: this.showMenuItem([Permission.SETTINGS_DICTIONARIES_READ]),
				},
				{
					text: $localize`Order templates`,
					link: '/settings?page=order-templates',
					isVisible: this.showMenuItem([Permission.ORDER_TEMPLATES_READ]),
				},
				{
					text: $localize`Task templates`,
					link: '/settings?page=task-templates',
					isVisible: this.showMenuItem([Permission.TASK_TEMPLATES_READ]),
				},
				{
					text: $localize`Regulations`,
					link: '/settings?page=regulations',
					isVisible: this.showMenuItem([Permission.REGULATIONS_READ]),
				},
				{
					text: $localize`Price-list`,
					link: '/settings?page=margin',
					isVisible: this.showMenuItem([Permission.MARGIN_MANAGEMENT]),
				},
			],
		},
		{
			text: $localize`Chat`,
			iconName: 'chat-square-dots',
			link: '/chat',
			isVisible: this.showMenuItem([Permission.MESSAGES_READ]),
		},
		{
			menuItemName: 'mailbox',
			text: $localize`Mailbox`,
			newItemAction: () => {
				this.loaderService.setContain().enable(async () => {
					await this.router.navigate(['/mailbox/new'])
				})
			},
			iconName: 'mail',
			isVisible: this.showMenuItem([Permission.MAIL_READ]),
			children: [
				{
					text: $localize`New email`,
					link: '/mailbox/new',
					isVisible: this.showMenuItem([Permission.MAIL_CREATE]),
				},
				{
					text: $localize`Inbox`,
					link: '/mailbox/inbox',
					isVisible: true,
				},
				{
					text: $localize`Outbox`,
					link: '/mailbox/outbox',
					isVisible: true,
				},
				{
					text: $localize`Drafts`,
					link: '/mailbox/drafts',
					isVisible: true,
				},
				{
					text: $localize`Contacts`,
					link: '/mailbox/contacts',
					isVisible: true,
				},
				{
					text: $localize`Mailbox settings`,
					link: '/mailbox/settings',
					isVisible: true,
				},
			],
		},
		{
			text: $localize`Log out`,
			iconName: 'logout',
			afterClick: () => this.authService.logout(),
			isVisible: true,
		},
	]
	menuItems: MenuItem[] = [
		{
			text: $localize`Dashboard`,
			link: '/home',
			isVisible: true,
			isAlwaysFocused: true,
			iconName: 'dashboard',
			customNewItemIcon: 'bell',
			newItemAction: () => {
				this.loaderService.setContain().enable(async () => {
					await this.router.navigate(['/notifications'])
				})
			},
		},
		{
			menuItemName: 'orders',
			text: $localize`Orders`,
			iconName: 'kanban',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([Permission.ORDERS_READ]),
			newItemAction: this.showMenuItem([Permission.ORDERS_CREATE])
				? () => {
						const serializedUrl = this.router.serializeUrl(this.router.createUrlTree(['/orders/create']))
						if (serializedUrl != this.router.url) {
							this.loaderService.setContain().enable(async () => {
								await this.router.navigateByUrl(serializedUrl)
							})
						}
					}
				: undefined,
			children: [
				{
					menuItemName: 'ordersList',
					text: $localize`Orders list`,
					link: '/orders',
					isVisible: this.showMenuItem([Permission.ORDERS_READ]),
				},
				{
					text: $localize`Add new order`,
					link: '/orders/create',
					isVisible: this.showMenuItem([Permission.ORDERS_CREATE]),
				},
				// {
				// 	text: $localize`My orders`,
				// 	comingSoon: true,
				// 	afterClick: () => this.notificationService.warning($localize`COMING SOON`),
				// },
			],
		},
		{
			menuItemName: 'contractors',
			text: $localize`List of groups of contractors`,
			iconName: 'customers',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([
				Permission.CONTRACTORS_READ,
				Permission.ENTITIES_READ,
				Permission.CONTRACTORS_CREATE,
				Permission.ENTITIES_CREATE,
			]),
			newItemAction: () => {
				const serializedUrl = this.router.serializeUrl(this.router.createUrlTree(['/contractors/create']))
				if (serializedUrl != this.router.url) {
					this.loaderService.setContain().enable(async () => {
						await this.router.navigateByUrl(serializedUrl)
					})
				}
			},
			children: [
				{
					menuItemName: 'contractorsList',
					text: $localize`List of groups of contractors`,
					link: '/contractors/groups',
					isVisible: this.showMenuItem([Permission.CONTRACTORS_READ]),
				},
				{
					menuItemName: 'entitiesList',
					text: $localize`List of contractors`,
					link: '/contractors',
					isVisible: this.showMenuItem([Permission.ENTITIES_READ]),
				},
				{
					text: $localize`Add contractor`,
					link: '/contractors/create',
					isVisible: this.showMenuItem([Permission.CONTRACTORS_CREATE, Permission.ENTITIES_CREATE]),
				},
			],
		},
		{
			text: $localize`Sales Documents`,
			iconName: 'sales-documents',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([Permission.INVOICES_READ, Permission.INVOICES_CREATE]),
			children: [
				{
					text: $localize`Sales documents list`,
					link: '/invoices',
					isVisible: this.showMenuItem([Permission.INVOICES_READ]),
				},
				{
					text: $localize`Add a VAT invoice`,
					link: '/invoices/create',
					isVisible: this.showMenuItem([Permission.INVOICES_CREATE]),
				},
				{
					text: $localize`Add a PROFORMA invoice`,
					link: '/invoices/create/proforma',
					isVisible: this.showMenuItem([Permission.INVOICES_CREATE]),
				},
			],
		},
		{
			text: $localize`Cost documents list`,
			iconName: 'cost-documents',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([Permission.COST_INVOICES_READ, Permission.COST_INVOICES_CREATE]),
			children: [
				{
					text: $localize`Cost invoices list`,
					link: '/cost-invoices',
					isVisible: this.showMenuItem([Permission.COST_INVOICES_READ]),
				},
				{
					text: $localize`Add a cost invoice`,
					link: '/cost-invoices/create',
					isVisible: this.showMenuItem([Permission.COST_INVOICES_CREATE]),
				},
			],
		},
		{
			text: $localize`Cash documents`,
			iconName: 'cash-documents',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([Permission.CASH_RECEIPTS_READ, Permission.CASH_DISBURSEMENTS_READ]),
			children: [
				{
					text: $localize`Cash receipts list`,
					link: `${config.modules.cashDocuments.path}/cash-receipts`,
					isVisible: this.showMenuItem([Permission.CASH_RECEIPTS_READ]),
				},
				{
					text: $localize`Cash disbursement list`,
					link: `${config.modules.cashDocuments.path}/cash-disbursement`,
					isVisible: this.showMenuItem([Permission.CASH_DISBURSEMENTS_READ]),
				},
			],
		},
		{
			text: $localize`OCR`,
			iconName: 'draft',
			isAlwaysFocused: true,
			isVisible: this.showMenuItem([Permission.OCR_READ]),
			demo: true,
			children: [
				{
					text: $localize`OCR`,
					link: '/ocr',
					demo: true,
					isVisible: this.showMenuItem([Permission.OCR_READ]),
				},
				{
					text: $localize`Invoice OCR`,
					link: '/ocr/invoice-ocr',
					demo: true,
					isVisible: this.showMenuItem([Permission.OCR_READ]),
				},
			],
		},

		// {
		// 	text: $localize`Daily report`,
		// 	iconName: 'calendar',
		// 	comingSoon: true,
		// 	afterClick: () => this.notificationService.warning($localize`COMING SOON`),
		// },
		// {
		// 	text: $localize`Finances`,
		// 	iconName: 'money',
		// 	children: [
		// 		{
		// 			text: $localize`Sell Documents`,
		// 			link: '/invoices',
		// 		},
		// 		{
		// 			text: $localize`Cost Documents`,
		// 			link: '/cost-invoices',
		// 		},
		// 	],
		// },
		// {
		// 	text: $localize`Invoices`,
		// 	iconName: 'money',
		// 	children: [
		// 		{
		// 			text: $localize`Invoices list`,
		// 			link: '/invoices',
		// 		},
		// 		{
		// 			text: $localize`Add new invoice`,
		// 			link: '/invoices/create',
		// 		},
		// 	],
		// },
	]
	private userHasOrders = false

	constructor(
		private authService: AuthService,
		public appService: AppService,
		private loaderService: LoaderService,
		private router: Router,
		public menuService: MenuService,
		private mailboxInnerService: MailboxInnerService,
		private mailService: MailService,
		private ordersInnerService: OrdersInnerService,
		private contractorInnerService: ContractorInnerService,
	) {
		super()
		this.setMailsAccounts()
		this.fetchUserHasTasks()
		this.fetchUserHasContractors()
		this.mailboxInnerService.refreshMenuAccountsList$.subscribe(() => {
			this.setMailsAccounts()
		})
	}

	/**
	 * Retrieves the username from the decoded payload.
	 *
	 * @return {string} The username extracted from the decoded payload. If the payload does not contain the username, an empty string is returned.
	 */
	get getUserName(): string {
		return this.authService.decodePayload()?.name ?? ''
	}

	/**
	 * Retrieves the role(s) of the current user.
	 *
	 * @return {string} The role(s) of the user as a comma-separated string.
	 * If the user has no roles, an empty string is returned.
	 */
	get getUserRole(): string {
		if (!this.authService.decodePayload()?.roles?.length) {
			return ''
		}
		return this.authService
			.decodePayload()!
			.roles!.map(role => role.name)
			.join(', ')
	}

	hideClock(): boolean {
		const serializedUrl = this.router.serializeUrl(this.router.createUrlTree(['/home']))
		return [serializedUrl].includes(this.router.url)
	}

	/**
	 * Navigates to the home page.
	 *
	 * @returns {void}
	 */
	goToHome(): void {
		const serializedUrl = this.router.serializeUrl(this.router.createUrlTree([`/${config.modules.home.path}`]))
		if (serializedUrl != this.router.url) {
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl(serializedUrl)
			})
		}
	}

	/**
	 * Shows the menu item based on the provided permissions.
	 *
	 * @param {Permission[]} permissions - The array of permissions required to display the menu item.
	 * @returns {boolean} - Returns true if the menu item should be shown, false otherwise.
	 */
	showMenuItem(permissions?: Permission[]): boolean {
		if (!permissions) {
			return true
		}

		return this.aclService.hasPermission(permissions)
	}

	openProfile(): void {
		if (this.router.url != '/settings?page=general') {
			this.loaderService.setContain().enable(async () => {
				await this.router.navigateByUrl('/settings?page=general')
			})
		}
	}

	avatarName(): string | undefined {
		return this.authService.decodePayload()?.avatar
	}

	/**
	 * Fetches mail accounts from the mail service.
	 *
	 * @return {void} - This method does not return a value.
	 */
	setMailsAccounts(): void {
		if (!this.aclService.hasPermission(Permission.MAIL_READ)) {
			return
		}
		this.mailService.getImapAccounts(undefined, 0, 50, undefined, false, 'desc', false).subscribe({
			next: response => {
				this.mailAccountsList = response.items
				if (!this.mailAccountsList) {
					return
				}

				const menuItem = this.menuItemsBottom.find(m => m.menuItemName === 'mailbox')
				if (!menuItem) {
					return
				}
				menuItem.children = []
				for (const account of this.mailAccountsList) {
					if (!account.username || !account.id) {
						continue
					}
					const urlAction = (url: string) => {
						this.mailboxInnerService.setSelectedAccount(account)
						if (this.router.url === url) {
							this.mailboxInnerService.selectedEmailAccountChange$.next()
							return
						}
						this.loaderService.setContain().enable(async () => {
							await this.router.navigateByUrl(url)
						})
					}
					const menuItems = [
						{
							text: $localize`New email`,
							isThirdMenuLevel: true,
							afterClick: () => {
								urlAction('/mailbox/new')
							},
							isVisible: this.showMenuItem([Permission.MAIL_CREATE]),
						},
						{
							text: $localize`Inbox`,
							isVisible: true,
							isThirdMenuLevel: true,
							afterClick: () => {
								urlAction('/mailbox/inbox')
							},
						},
						{
							text: $localize`Outbox`,
							isVisible: true,
							isThirdMenuLevel: true,
							afterClick: () => {
								urlAction('/mailbox/outbox')
							},
						},
						{
							text: $localize`Drafts`,
							isVisible: true,
							isThirdMenuLevel: true,
							afterClick: () => {
								urlAction('/mailbox/drafts')
							},
						},
					]
					menuItem.children.push({
						text: account.username,
						isVisible: true,
						children: menuItems,
						afterClick: () => {
							urlAction('/mailbox/inbox')
						},
					})
				}
				menuItem.children.push({
					text: $localize`Contacts`,
					link: '/mailbox/contacts',
					isVisible: true,
				})
				menuItem.children.push({
					text: $localize`Mailbox settings`,
					link: '/mailbox/settings',
					isVisible: true,
				})
			},
			error: () => {
				console.error('Failed to fetch mail accounts')
			},
		})
	}

	private fetchUserHasTasks(): void {
		if (this.aclService.hasPermission(Permission.ORDERS_READ)) {
			return
		}
		this.ordersInnerService.currentUserHasOrders().subscribe(hasOrders => {
			if (!hasOrders) {
				return
			}
			const menuItem = this.menuItems.find(m => m.menuItemName === 'orders')
			if (!menuItem) {
				return
			}
			const menuItemList = menuItem.children?.find(m => m.menuItemName === 'ordersList')
			if (!menuItemList) {
				return
			}
			menuItem.isVisible = true
			menuItemList.isVisible = true
		})
	}

	private fetchUserHasContractors(): void {
		if (this.aclService.hasPermission(Permission.CONTRACTORS_READ)) {
			return
		}

		this.contractorInnerService.currentUserHasContractors().subscribe(hasContractors => {
			if (!hasContractors) {
				return
			}

			const menuItem = this.menuItems.find(m => m.menuItemName === 'contractors')
			if (!menuItem) {
				return
			}

			const menuItemList = menuItem.children?.find(m => m.menuItemName === 'contractorsList')
			if (!menuItemList) {
				return
			}

			const entitiesList = menuItem.children?.find(m => m.menuItemName === 'entitiesList')
			if (!entitiesList) {
				return
			}

			menuItem.isVisible = true
			menuItemList.isVisible = true
			entitiesList.isVisible = true
		})
	}
}
