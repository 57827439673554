<div
	(clickOutside)="clickOutside()"
	[class.epic-menu-item--active]="isActive()"
	[class.epic-menu-item--collapsed]="!isMenuExpanded()"
	[class.epic-menu-item--coming-soon]="options.comingSoon"
	[class.epic-menu-item__container--group]="isGroup()"
	[class.epic-menu-item__container--third]="options.isThirdMenuLevel"
	class="epic-menu-item__container">
	@if (isGroup()) {
		<div
			[epic-tooltip]="isMenuExpanded() ? undefined : options.text"
			#epicMenuItem
			class="epic-menu-item epic-menu-item__submenu"
			[class.is-focused]="options.isAlwaysFocused && isMenuExpanded()"
			[class.epic-menu-item--expanded]="!collapseState"
			[class.epic-menu-item--not-expanded]="!isMenuExpanded()"
			(click)="collapseState = !collapseState">
			<div class="epic-menu-item__label">
				@if (isIcon()) {
					<epic-icon
						[source]="options.iconSource"
						[name]="options.iconName" />
				}
				@if (isMenuExpanded()) {
					@if (options.text) {
						{{ options.text }}
					} @else {
						<ng-content />
					}
					<div class="epic-menu-item__submenu-indicator">
						<epic-icon name="expand-more" />
					</div>

					@if (options.description) {
						<div class="epic-menu-item__description">
							{{ options.description }}
						</div>
					}
				}

				@if (isMenuExpanded()) {
					@if (options.demo ?? false) {
						<div class="demo">Demo</div>
					}
				}
			</div>

			@if (isMenuExpanded()) {
				@if (options?.newItemAction) {
					<div class="epic-menu-item__new-item"
					     (click)="options?.newItemAction(); $event.stopPropagation()">
						<div class="wrapper">
							<epic-icon name="new_item" />
						</div>
					</div>
				}
			} @else {
				<epic-icon name="arrow-right" />
			}
		</div>
		<div
			*ngIf="isMenuExpanded()"
			class="epic-menu-item__submenu-items"
			[class.epic-menu-item__submenu-items--expanded]="collapseState"
			[@collapse]="{ value: collapseState, params: { duration: animationDuration, delay: 0 } }">
			@for (childOptions of options.children; track childOptions) {
				<epic-menu-item
					*ngIf="childOptions.isVisible"
					[options]="childOptions"
					[isSubmenuItem]="true" />
			}
		</div>
		<epic-popover
			class="font__medium"
			*ngIf="!isMenuExpanded()"
			[parentReference]="epicMenuItem">
			<div class="epic-menu-item__label"
			     popover-header>
				@if (isIcon()) {
					<epic-icon
						[source]="options.iconSource"
						[name]="options.iconName" />
				}
				{{ options.text }}
			</div>
			<div
				popover-content>
				@for (childOptions of options.children; track childOptions) {
					<div
						class="epic-popover__item"
						(click)="open(childOptions.link)"
						(auxclick)="openInNewTab(childOptions.link)">
						{{ childOptions.text }}
					</div>
				}
			</div>
		</epic-popover>
	} @else {
		@if (isAfterClickFunction()) {
			<div
				class="epic-menu-item"
				[class.epic-menu-item--not-expanded]="!isMenuExpanded()"
				[class.is-focused]="options.isAlwaysFocused && isMenuExpanded()"
				(click)="afterClick()">
				<div class="epic-menu-item__label">
					@if (isIcon()) {
						<epic-icon
							[source]="options.iconSource"
							[name]="options.iconName" />
					}
					@if (isMenuExpanded()) {
						@if (options.text) {
							{{ options.text }}
						} @else {
							<ng-content />
						}

						@if (options.description) {
							<div class="epic-menu-item__description">
								{{ options.description }}
							</div>
						}
					}
				</div>
			</div>
		} @else if (!isExternalLink()) {
			<a
				class="epic-menu-item"
				[class.epic-menu-item--not-expanded]="!isMenuExpanded()"
				[class.is-focused]="options.isAlwaysFocused && isMenuExpanded()"
				(click)="open(options.link)"
				(auxclick)="openInNewTab(options.link)">
				<div class="epic-menu-item__label">
					@if (isIcon()) {
						<epic-icon
							[source]="options.iconSource"
							[name]="options.iconName" />
					}

					@if (isMenuExpanded()) {
						@if (options.text) {
							{{ options.text }}
						} @else {
							<ng-content />
						}

						@if (options.description) {
							<div class="epic-menu-item__description">
								{{ options.description }}
							</div>
						}
					}

					@if (options?.demo) {
						<div class="demo">Demo</div>
					}
				</div>
				@if (isMenuExpanded() && options?.customNewItemIcon && options?.newItemAction) {
					<div class="epic-menu-item__new-item--custom"
					     (click)="options?.newItemAction(); $event.stopPropagation()">
						<epic-icon [name]="options?.customNewItemIcon" />
					</div>
				}
			</a>
		} @else {
			<a
				class="epic-menu-item"
				[class.epic-menu-item--not-expanded]="!isMenuExpanded()"
				[class.is-focused]="options.isAlwaysFocused && isMenuExpanded()"
				[href]="options.link">
				<div class="epic-menu-item__label">
					@if (isIcon()) {
						<epic-icon
							[source]="options.iconSource"
							[name]="options.iconName" />
					}
					@if (isMenuExpanded()) {
						@if (options.text) {
							{{ options.text }}
						} @else {
							<ng-content />
						}

						@if (options.description) {
							<div class="epic-menu-item__description">
								{{ options.description }}
							</div>
						}
					}
				</div>
			</a>
		}
		@if (isButton()) {
			<button
				class="epic-menu-item__button"
				(click)="afterButtonClick()">
				<epic-icon
					[name]="options.button?.iconName"
					[source]="options.button?.iconSource" />
			</button>
		}
	}
</div>
