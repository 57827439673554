import { CommonModule } from '@angular/common'
import { Component, ElementRef } from '@angular/core'
import { IconComponent } from '@core/components/icon/icon.component'
import { MenuService } from '@core/services/menu.service'


@Component({
	selector: 'epic-menu',
	standalone: true,
	imports: [CommonModule, IconComponent],
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
	constructor(
		private menuService: MenuService,
		private elementRef: ElementRef,
	) {}

	ngOnInit() {
		this.menuService.menuReference = this.elementRef
	}

	toggleCollapse(): void {
		this.menuService.toggleCollapse()
	}

	collapseValue(): boolean {
		return this.menuService.collapseValue()
	}
}
