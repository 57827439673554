import { Component, HostListener, OnInit } from '@angular/core'
import { NavigationEnd, Router, RouterOutlet } from '@angular/router'
import { EpicAppComponent } from '@core/components/app/app.component'
import { InternalServerErrorPage } from '@app/common-pages/internal-server-error/internal-server-error.page'
import { NotFoundPage } from '@app/common-pages/not-found/not-found.page'
import { CommandLineComponent } from '@app/system/command-line/command-line.component'
import { NotificationService } from '@core/services/notification.service'
import { ModalService } from '@core/services/modal.service'
import { AppInfoComponent } from '@app/app/app-info.component'
import { LanguageService } from '@core/services/language.service'
import { config } from '@config'
import { LanguageSwitcherComponent } from '@core/components/language-switcher/language-switcher.component'
import { LanguageCode } from '@core/enums/language-code.enum'
import { MenuService } from '@core/services/menu.service'
import { AppService } from '@app/general/services/app.service'
import { LoaderService } from '@core/services/loader.service'
import { AuthService } from '@app/auth/services/auth.service'
import { SocketsService } from '@app/general/services/sockets.service'
import { filter, Subscription } from 'rxjs'
import { RegulationsForm } from '@app/settings/forms/user-regulations/user-regulations.form'
import { EquipmentsService, EquipmentUsePolicyAcceptanceRead } from '@app/generated'
import { ChatModule } from '@app/chat/chat.module'
import { TokenRequest, TokenResponse } from '@app/auth/models/token.model'
import { Logger } from '@app/general/utils/logger.util'

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [
		RouterOutlet,
		EpicAppComponent,
		InternalServerErrorPage,
		NotFoundPage,
		CommandLineComponent,
		LanguageSwitcherComponent,
		ChatModule,
	],
	template: `
		<epic-language-switcher (change)="changeLanguage($event)" />
		@if (showCommandLine) {
			<erp-command-line
				(close)="showCommandLine = false"
				(command)="handleCommand($event)" />
		}
		@if (internalServerError) {
			<internal-server-error />
		}
		<epic-app>
			<router-outlet />
		</epic-app>
	`,
	styles: [],
})
export class AppComponent implements OnInit {
	showCommandLine: boolean = false
	internalServerError: boolean = false

	constructor(
		private languageService: LanguageService,
		private notificationService: NotificationService,
		private modalService: ModalService,
		private menuService: MenuService,
		private appService: AppService,
		private loaderService: LoaderService,
		private authService: AuthService,
		private socketsService: SocketsService,
		private router: Router,
		private equipmentsService: EquipmentsService,
	) {
		this.loaderService.withoutFadeInOnEnterAnimation().enable()
		this.languageService.initLanguages(config.availableLanguages)
		this.menuService.setMenuWidth({ expanded: 250, collapsed: 64 })
		this.appService.internalServerErrorChanges().subscribe(value => {
			this.internalServerError = value
		})
		const subscription: Subscription = this.authService.afterLoggingIn.subscribe(value => {
			if (value) {
				console.log('After logging in')
				this.socketsService.connect()
				subscription.unsubscribe()
			}
		})
		this.openUserRegulationsModal()
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
			if (authService.getUserIdFromToken()) {
				this.openUserRegulations()
			}
		})
		this.refreshJwtToken()
	}

	@HostListener('window:keydown.control.shift.l', ['$event'])
	@HostListener('window:keydown.control.shift.p', ['$event'])
	openCommandLine(event: KeyboardEvent): void {
		event.preventDefault()
		this.showCommandLine = true
	}

	ngOnInit() {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
			this.modalService.close()
		})
	}

	async handleCommand(command: string): Promise<void> {
		this.showCommandLine = false

		switch (command) {
			case 'reload': {
				window.location.reload()
				break
			}
			case '!info': {
				const modal = await this.modalService.open(AppInfoComponent)
				modal.title = $localize`App Info`
				modal.iconName = 'settings'
				break
			}
			case '!500': {
				this.internalServerError = true
				break
			}
			default: {
				this.notificationService.error('Unknown command')
			}
		}
	}

	openUserRegulations() {
		if (sessionStorage.getItem('acceptance_of_the_regulations') !== 'ACCEPTED') {
			this.equipmentsService.getEquipmentUsePolicyAcceptanceForCurrentUser().subscribe({
				next: (response: EquipmentUsePolicyAcceptanceRead) => {
					if (response.user.regulations_status === 'REJECTED') {
						this.openUserRegulationsModal()
					}
				},
				error: () => {
					this.notificationService.error($localize`Failed to retrieve equipment use policy acceptance`)
				},
			})
		}
	}

	async openUserRegulationsModal() {
		const modal = await this.modalService.open(RegulationsForm)
		modal.title = 'Rules'
		modal.iconName = 'show-all'
		modal.width = '1000px'

		this.modalService.modalRef.closed.subscribe((confirmed: boolean) => {
			if (confirmed) {
				sessionStorage.setItem('acceptance_of_the_regulations', 'ACCEPTED')
			}
		})
	}

	changeLanguage(language: LanguageCode) {
		this.languageService.setAppLanguage(language)
	}

	private refreshJwtToken(): void {
		const logger: Logger = new Logger('Refresh JWT token')

		const refresh = () => {
			if (this.authService.tokenIsSet()) {
				if (this.authService.refreshToken) {
					logger.info('Refreshing JWT token')
					this.authService.getToken(TokenRequest.withRefreshToken(this.authService.refreshToken)).subscribe({
						next: (response: TokenResponse) => {
							logger.info('JWT token has been refreshed')
							this.authService.saveToken(response.token, response.refresh_token)
						},
					})
				}
			}
		}

		refresh()

		setInterval(() => {
			refresh()
		}, 300_000)
	}
}
